import { Injectable } from '@angular/core'
import { BehaviorSubject, combineLatest } from 'rxjs'
import { NavController } from '@ionic/angular'
import { NavPage } from '../interfaces/navigation'

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  // navigation pipe
  public navPageTarget$: BehaviorSubject<NavPage> = new BehaviorSubject({ targetUrl: '/' })
  public navPageReport$: BehaviorSubject<string> = new BehaviorSubject('')

  constructor(public navCtl: NavController) {}

  /* Lifecycle */
  async onInit() {
    console.info('NavigationService.onInit()..')
  }

  // Init() is called from app.module.ts and will block the app until finished
  Init() {
    return new Promise<void>(async (resolve, reject) => {
      console.info('NavigationService.init()..')
      await this.onInit()

      console.log('NavigationService: Ready')
      resolve()
    })
  }

  /* Service Functions */
  public get navCombinePageTargetAndReport$() {
    return combineLatest({ nPT: this.navPageTarget$, nPR: this.navPageReport$ })
  }

  public async navLoadAfterSend() {
    let nextNavPageTarget = this.navPageTarget$.value
    if (nextNavPageTarget.status == 'send') {
      nextNavPageTarget.status = 'load'
      this.navPageTarget$.next(nextNavPageTarget)
    }
  }

  public async navHome() {
    this.navPageTarget$.next({ targetUrl: '/' })
  }

  public async navTabs(dataRequest?: string) {
    this.navPageTarget$.next({ targetUrl: '/tabs', dataRequest: dataRequest })
  }

  public async navInstructions() {
    await this.navCtl.navigateRoot('/tabs/instructions')
  }

  public async navLocations(dataRequest?: string) {
    this.navPageTarget$.next({ targetUrl: '/tabs/loc', dataRequest: dataRequest })
  }

  public async navLocation(dataRequest?: string) {
    this.navPageTarget$.next({ targetUrl: '/tabs/loc/l', dataRequest: dataRequest })
  }

  public async navRecord() {
    await this.navCtl.navigateForward('/tabs/loc/l/r')
  }

  public async navLoading() {
    this.navPageTarget$.next({ targetUrl: '/loading' })
  }

  public async navAbout() {
    await this.navCtl.navigateForward('/about')
  }

  public async navLogin() {
    await this.navCtl.navigateForward('/login')
  }

  public async navMeasurement(dataRequest?: string) {
    this.navPageTarget$.next({
      targetUrl: '/tabs/loc/l/m',
      dataRequest: dataRequest,
      direction: 'forward',
    })
  }

  public async navPermissions() {
    await this.navCtl.navigateForward('/tabs/settings/permissions')
  }

  public async navSettings() {
    await this.navCtl.navigateRoot('/tabs/settings')
  }

  public async navSoftware() {
    await this.navCtl.navigateForward('/tabs/settings/software')
  }
}
