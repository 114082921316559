import { KeyValue } from '@angular/common'
import { Component } from '@angular/core'
import { RootService } from 'src/app/services/root.service'

@Component({
  selector: 'page-loading',
  templateUrl: 'loading.html',
  styleUrls: ['./loading.scss'],
})
export class LoadingPage {
  loadingTimeOut: boolean = false

  constructor(public root: RootService) { }

  /* Lifecycle */
  async ngOnInit() {
    console.info('ngOnInit: loading')
    setTimeout(() => {
      this.loadingTimeOut = true
    }, 60000) // loading time out after 60 seconds
  }

  async ionViewWillEnter() {
    console.info('ionViewWillEnter: loading')
  }

  async ionViewDidEnter() {
    console.info('ionViewDidEnter: loading')
    this.root.trunk.reportContext('page', this.root.router.url)
  }

  async ionViewWillLeave() {
    console.info('ionViewWillLeave: loading')
  }

  async ionViewDidLeave() {
    console.info('ionViewDidLeave: loading')
  }

  async ngOnDestroy() {
    console.info('ngOnDestroy: loading')
  }

  /* PAGE FUNCTIONS */
  public async originalOrder(a: KeyValue<number, string>, b: KeyValue<number, string>) {
    return 0
  }
  public async valueAscOrder(a: KeyValue<number, string>, b: KeyValue<number, string>) {
    return a.value.localeCompare(b.value)
  }
  public async keyDescOrder(a: KeyValue<number, string>, b: KeyValue<number, string>) {
    return a.key > b.key ? -1 : b.key > a.key ? 1 : 0
  }
}
