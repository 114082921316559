import { Component } from '@angular/core'
import { RootService } from 'src/app/services/root.service'
import { Clipboard } from '@capacitor/clipboard'
import { Toast } from '@capacitor/toast'

@Component({
  selector: 'page-about',
  templateUrl: 'about.html',
  styleUrls: ['./about.scss'],
})
export class AboutPage {
  public emailContact: string = 'info@groundwater.global'

  constructor(public root: RootService) {}

  /* Lifecycle */
  async ngOnInit() {
    console.info('ngOnInit: about')
  }

  async ionViewDidEnter() {
    console.info('ionViewDidEnter: about')
    this.root.trunk.reportContext('page', this.root.router.url)
  }

  /* Handles */
  async handleBack() {
    await this.root.trunk.reportLog('handleBack()')
  }

  public writeToClipboard = async () => {
    await Clipboard.write({ string: this.emailContact })
    Toast.show({
      text: 'Email copied!',
      duration: 'short',
      position: 'bottom',
    })
  }
}
