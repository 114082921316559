<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button defaultHref="/"></ion-back-button>
    </ion-buttons>
    <ion-title>Log In</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="logo-container">
    <ion-icon class="logo-svg" src="assets/img/logo.svg"></ion-icon>
  </div>

  <div class="login-form">
    <form (ngSubmit)="handleLogin()">
      <ion-grid>
        <ion-row>
          <ion-list class="input-list">
            <ion-item class="input-item">
              <ion-input
                placeholder="Username"
                type="text"
                name="user"
                [(ngModel)]="root.trunk.cache.personals.username"
                required="required"
              ></ion-input>
            </ion-item>
            <ion-item class="input-item">
              <ion-input
                placeholder="Password"
                type="password"
                name="password"
                [(ngModel)]="root.trunk.trash.password"
                required="required"
                ><ion-input-password-toggle slot="end"></ion-input-password-toggle
              ></ion-input>
            </ion-item>
          </ion-list>
        </ion-row>
        <ion-row class="button-row">
          <ion-button color="primary" expand="block" type="submit" size="large">
            Log In
          </ion-button>
          <ion-button fill="clear" (click)="handleForgotPassword()"> Forgot password? </ion-button>
        </ion-row>
      </ion-grid>
    </form>
  </div>
</ion-content>
