import { Component, OnInit } from '@angular/core'
import { RootService } from 'src/app/services/root.service'

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {
  constructor(public root: RootService) { }

  /* Lifecycle */
  async ngOnInit() {
    console.info('ngOnInit: login')
  }

  async ionViewWillEnter() {
    console.info('ionViewWillEnter: login')
  }

  async ionViewDidEnter() {
    console.info('ionViewDidEnter: login')
  }

  async ionViewWillLeave() {
    console.info('ionViewWillLeave: login')
  }

  async ionViewDidLeave() {
    console.info('ionViewDidLeave: login')
  }

  async ngOnDestroy() {
    console.info('ngOnDestroy: login')
  }

  /* Handles */
  async handleLogin() {
    try {
      await this.root.onLogin()
    } catch (error) {
      console.debug(error)
    }
  }

  public async handleToAccount() {
    await this.root.trunk.reportLog('handleToAccount()')
    await this.root.trunk.navExtAccount()
  }
  public async handleForgotPassword() {
    await this.root.trunk.reportLog('handleForgotPassword()')
    await this.root.trunk.navForgotPassword()
  }
}
