import { NgModule } from '@angular/core'
import { PreloadAllModules, RouterModule, Routes } from '@angular/router'
// import { AuthGuard } from './guards/auth.guard';
import { HomePage } from '../pages/home/home.page'
import { LoginPage } from '../pages/login/login.page'
import { AboutPage } from '../pages/about/about'
import { LoadingPage } from '../pages/loading/loading'
import { authenticationGuard } from './guards/auth.guard'

const routes: Routes = [
  {
    path: '',
    component: HomePage,
  },
  {
    path: 'login',
    component: LoginPage,
  },
  {
    path: 'about',
    component: AboutPage,
  },
  {
    path: 'loading',
    canActivate: [authenticationGuard],
    component: LoadingPage,
  },
  {
    path: 'tabs',
    canActivate: [authenticationGuard],
    loadChildren: () => import('./tabs/tabs.module').then((m) => m.TabsModule),
  },
]
@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
