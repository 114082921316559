<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button defaultHref="/" (click)="handleBack()"></ion-back-button>
    </ion-buttons>
    <ion-title>About</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="logo-container">
    <ion-icon class="logo-svg" src="assets/img/logo.svg"></ion-icon>
  </div>

  <ion-list [inset]="true">
    <ion-item lines="none">
      <p>
        Groundwater Global Foundation is a non-profit organisation with the goal to aid communities
        in the management of their groundwater resources.
      </p>
    </ion-item>
    <ion-item lines="none">
      <p>Groundwater is the crucial resource that we turn to in times of drought.</p>
    </ion-item>
    <ion-item lines="none">
      <p>Globally, groundwater resources are under pressure.</p>
    </ion-item>
    <ion-item lines="none">
      <p>
        At the core of our mission, we help by enabling everyone who owns a mobile phone to measure
        their groundwater levels.
      </p>
    </ion-item>
    <ion-item lines="none">
      <div>
        <p>Feel free to contact us at:</p>
        <ion-button (click)="writeToClipboard()">
          {{emailContact}}
          <ion-icon name="copy-outline" slot="end"></ion-icon>
        </ion-button>
      </div>
    </ion-item>
  </ion-list>
</ion-content>
