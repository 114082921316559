import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  mapModal!: HTMLIonModalElement
  mapModalSubscription!: Subscription

  constructor(
    public modalCtrl: ModalController
  ) { }

  /* Lifecycle */
  async onInit() {
    console.info('ModalService.onInit()..')
  }

  // Init() is called from app.module.ts and will block the app until finished
  Init() {
    return new Promise<void>(async (resolve, reject) => {
      console.info('ModalService.init()..')
      await this.onInit()

      console.info('ModalService: Ready')
      resolve()
    })
  }
}
