import { Injectable } from '@angular/core'
import { sleep } from '../util/helpers'
import { Toast } from '@capacitor/toast'

@Injectable({
  providedIn: 'root',
})
export class RecordwavService {
  // init
  private chunks: any[] = []
  private mediaRecorder!: MediaRecorder
  private audioStream!: MediaStream
  public audioTracks!: MediaStreamTrack[]
  public wavBlob!: Blob

  // constant audio settings (mono 16bit)
  private bitDepth: number = 16
  private nChannels: number = 1
  private sampleRate: number = 48000

  // reported track stuff
  public audioStreamTrackSettings!: MediaTrackSettings
  public audioStreamTrackSettingsJSON!: any
  public audioStreamTrackCapabilities!: any
  public audioStreamTrackCapabilitiesJSON!: any

  constructor() {}

  async startRecording() {
    console.debug('RecordwavService.startRecording()..')
    // if (this.audioContext.state === 'suspended') {
    //   await this.audioContext.resume()
    // }

    this.audioStream = await navigator.mediaDevices.getUserMedia({
      audio: {
        autoGainControl: false,
        channelCount: this.nChannels,
        echoCancellation: false,
        noiseSuppression: false,
        // sampleRate: this.sampleRate,     // this will specify a desired sample rate, maybe not the actual samplerate

        // @ts-ignore: googAutoGainControl (supposed old google option to turn of AutoGain)
        googAutoGainControl: false,

        // @ts-ignore: volume (apparently does not work in firefox!)
        // volume: 0.5,
      },
    })

    // check tracks
    let tracks: MediaStreamTrack[] = this.audioStream.getTracks()
    console.debug(tracks)

    for (let i = 0; i < tracks.length; i++) {
      let track = tracks[i]

      // let capabilities = await track.getCapabilities()
      // console.debug(capabilities)

      // await track.applyConstraints({
      //   channelCount: 1,
      // })

      this.audioStreamTrackSettings = await track.getSettings()
      console.debug(this.audioStreamTrackSettings)

      this.audioStreamTrackCapabilities = await track.getCapabilities()
      console.debug(this.audioStreamTrackCapabilities)

      this.audioStreamTrackSettingsJSON = JSON.parse(JSON.stringify(this.audioStreamTrackSettings))
      this.audioStreamTrackCapabilitiesJSON = JSON.parse(
        JSON.stringify(this.audioStreamTrackCapabilities, function replacer(key, value) {
          return value
        })
      )
    }

    // make sure samplerate complies with used settings in audio stream
    if (this.audioStreamTrackSettings.sampleRate) {
      this.sampleRate = this.audioStreamTrackSettings.sampleRate
    }

    let mediaRecorderOptions: MediaRecorderOptions = {
      audioBitsPerSecond: this.nChannels * this.bitDepth * this.sampleRate,

      // mimeType: 'audio/mp4; codecs="pcm"',

      // @ts-ignore: audioBitrateMode (should be in the typescript interface?)
      audioBitrateMode: 'constant',
    }
    this.mediaRecorder = new MediaRecorder(this.audioStream, mediaRecorderOptions)
    this.mediaRecorder.ondataavailable = (event: any) => {
      // debug  show audioBitsPerSecond
      let stringAudioBitsPerSecond = 'audioBitsPerSecond: ' + this.mediaRecorder.audioBitsPerSecond
      console.debug(stringAudioBitsPerSecond)
      // Toast.show({
      //   text: stringAudioBitsPerSecond,
      //   duration: 'long',
      //   position: 'bottom',
      // })

      // console.debug('Pushing chunk..')
      console.debug(event.data)
      this.chunks.push(event.data)

      // Toast.show({
      //   text: event.data.type,
      //   duration: 'long',
      //   position: 'bottom',
      // })
    }
    this.mediaRecorder.start()
  }

  async stopRecording() {
    console.debug('RecordwavService.stopRecording()..')
    if (this.mediaRecorder) {
      this.mediaRecorder.onstop = async () => {
        // init blob from recording chunks
        const audioBlob = new Blob(this.chunks)

        // clean up list of chunks
        this.chunks = []

        // end audiostream (all tracks)
        await this.cleanUpAudiostream()

        // if no data end here
        // console.debug(audioBlob)
        if (audioBlob.size == 0) {
          return
        }

        // get array buffer from chunks (seems a bit indirect)
        const audioData = await audioBlob.arrayBuffer()
        console.debug()

        // set samplerate for downsampling
        let downSampleRate = 22050
        if (
          this.audioStreamTrackSettings.sampleRate == 48000 ||
          this.audioStreamTrackSettings.sampleRate == 32000 ||
          this.audioStreamTrackSettings.sampleRate == 16000
        ) {
          downSampleRate = 16000
        }
        if (this.audioStreamTrackSettings.sampleRate == 44100) {
          downSampleRate = 22050
        }

        // downsampling based on tune
        // if (this.selectedTune.includes('3s')) {
        //   this.downSampleRate = 22050
        // }
        // if (this.selectedTune.includes('5s')) {
        //   this.downSampleRate = 22050
        // }
        // if (this.selectedTune.includes('16s')) {
        //   this.downSampleRate = 22050
        // }

        // decode default audio data to set characteristics defined in audioContext
        const audioDataDecoded = await this.decodeAudioData(audioData, downSampleRate)

        // deal with channels (a bit overly complex for mono sound)
        const channelBuffers = await this.audioDataToChannelBuffers(audioDataDecoded)

        // generate wav file (include WAV header and convert floatTo16BitPCM)
        const wavFile = await this.audioDataToWav(downSampleRate, channelBuffers)

        // assign wav file to wav blob
        this.wavBlob = new Blob([wavFile])
      }

      // stop recorder
      this.mediaRecorder.stop()
    }
  }

  private async decodeAudioData(audioData: ArrayBuffer, sampleRate: number) {
    const audioContext = new AudioContext({ sampleRate: sampleRate })
    const audioDataDecoded = await audioContext.decodeAudioData(audioData)
    audioContext.close()
    return audioDataDecoded
  }

  private async audioDataToChannelBuffers(audioDataDecoded: AudioBuffer) {
    const channelBuffers = []
    for (let channel = 0; channel < this.nChannels; channel++) {
      channelBuffers.push(audioDataDecoded.getChannelData(channel))
    }
    return channelBuffers
  }

  private async audioDataToWav(sampleRate: number, channelBuffers: any[]) {
    const totalSamples = channelBuffers[0].length * this.nChannels
    const byteDepth = this.bitDepth / 8

    const buffer = new ArrayBuffer(44 + totalSamples * byteDepth)
    const view = new DataView(buffer)

    const writeString = (view: DataView, offset: number, string: string) => {
      for (let i = 0; i < string.length; i++) {
        view.setUint8(offset + i, string.charCodeAt(i))
      }
    }

    /* RIFF identifier */
    writeString(view, 0, 'RIFF')
    /* RIFF chunk length */
    view.setUint32(4, 36 + totalSamples * byteDepth, true)
    /* RIFF type */
    writeString(view, 8, 'WAVE')
    /* format chunk identifier */
    writeString(view, 12, 'fmt ')
    /* format chunk length */
    view.setUint32(16, 16, true)
    /* sample format (raw) */
    view.setUint16(20, 1, true)
    /* channel count */
    view.setUint16(22, this.nChannels, true)
    /* sample rate */
    view.setUint32(24, sampleRate, true)
    /* byte rate (sample rate * block align) */
    view.setUint32(28, (sampleRate * this.bitDepth * this.nChannels) / 8, true)
    /* block align (channel count * bytes per sample) */
    view.setUint16(32, this.nChannels * byteDepth, true)
    /* bits per sample */
    view.setUint16(34, this.bitDepth, true)
    /* data chunk identifier */
    writeString(view, 36, 'data')
    /* data chunk length */
    view.setUint32(40, totalSamples * byteDepth, true)

    // floatTo16BitPCM
    let offset = 44
    for (let i = 0; i < channelBuffers[0].length; i++) {
      for (let channel = 0; channel < this.nChannels; channel++) {
        const s = Math.max(-1, Math.min(1, channelBuffers[channel][i]))
        view.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true)
        offset += 2
      }
    }

    return buffer
  }

  private async cleanUpAudiostream() {
    console.debug('h. cleanUpAudiostream()')
    this.audioTracks = this.audioStream.getAudioTracks()
    console.debug('audioStream: ' + this.audioStream.active.toString())
    await sleep(500)
    for (let track of this.audioTracks) {
      let msg = 'stopping microphone track: ' + track.id.toString()
      console.debug(msg)
      track.stop()
    }
  }
}
