<ion-content scroll-y="true">
  <div class="content">
    <div class="title">
      <div>Groundwater</div>
      <div>Global</div>
    </div>

    <div class="logo-container">
      <ion-icon class="logo-svg" src="assets/img/logo.svg"></ion-icon>
    </div>

    <ion-note width="100vw" class="ion-text-center">
      {{ root.trunk.cache.appVersion.tag }}
    </ion-note>
    <ion-note *ngIf="!this.root.trunk.trash.production" width="100vw" class="ion-text-center">
      {{ root.trunk.cache.timestampCompile | date : 'dd-MM-yy HH:mm' }}
    </ion-note>
    <ion-grid *ngIf="!root.trunk.trash.userHasLoggedOut" class="buttons">
      <ion-row>
        <ion-col>
          <ion-button color="primary" expand="block" (click)="handleToLoginPage()" size="large">
            Log In
          </ion-button>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-button
            color="primary"
            expand="block"
            fill="outline"
            (click)="handleToRegistration()"
            size="large"
          >
            Sign Up
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>

    <ion-grid *ngIf="root.trunk.trash.userHasLoggedOut">
      <ion-row>
        <ion-text>You have successfully logged out!</ion-text>
      </ion-row>
      <ion-row>
        <ion-text>Please close and re-open this app.</ion-text>
      </ion-row>
    </ion-grid>

    <ion-card (click)="root.trunk.nav.navAbout()" class="info-card" color="dark">
      <div class="info-img"></div>
      <ion-card-content>
        <ion-text>About the foundation</ion-text>
        <ion-icon class="info-icon" name="information-circle-outline"></ion-icon>
      </ion-card-content>
    </ion-card>
  </div>
</ion-content>
