import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IPermissionsNative, IPermissionsPWA } from '../interfaces/permissions';
import { Toast } from '@capacitor/toast';
import { Geolocation, GeolocationPluginPermissions } from '@capacitor/geolocation';


@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
  public permissionsPWA: IPermissionsPWA
  public permissionsNative: IPermissionsNative

  constructor() {
    this.permissionsPWA = {
      camera$: new BehaviorSubject({} as PermissionStatus),
      geolocation$: new BehaviorSubject({} as PermissionStatus),
      microphone$: new BehaviorSubject({} as PermissionStatus),
    }
    this.permissionsNative = {
      coarseLocation$: new BehaviorSubject("denied"),
      location$: new BehaviorSubject("denied"),
    }
  }

  /* Lifecycle */
  private async onInit() {
    console.info('PermissionsService.onInit()..')
  }

  // Init() is called from app.module.ts and will block the app until finished
  Init() {
    return new Promise<void>(async (resolve, reject) => {
      console.info('PermissionsService.init()..')
      await this.onInit()

      console.info('PermissionsService: Ready')
      resolve()
    })
  }

  /* Methods */
  public async checkPermissions(platformType: string) {
    if (platformType == 'pwa') { this.checkPermissionsPWA() }
    if (platformType == 'native') { this.checkPermissionNativeGeolocation() }
  }

  public async requestPermissionGeolocation(platformType: string) {
    if (platformType == 'native') {
      this.requestPermissionNativeGeolocation()
    } else {
      console.debug('requestPermissions() not implemented on web')
    }
  }

  /* PWA */
  private async checkPermissionsPWA() {
    console.debug('checkPermissionsPWA()')
    try {
      this.permissionsPWA.geolocation$.next(await navigator.permissions.query({ name: "geolocation" }));
      // @ts-ignore: camera permission (apparently does not work in firefox!)
      this.permissionsPWA.camera$.next(await navigator.permissions.query({ name: "camera" }));
      // @ts-ignore: microphone permission (apparently does not work in firefox!)
      this.permissionsPWA.microphone$.next(await navigator.permissions.query({ name: "microphone" }));
    } catch (error) {
      console.debug(error)
    }
  }

  /* Native */
  private async checkPermissionNativeGeolocation() {
    console.debug('Geolocation checkPermissions()')
    try {
      let permissionStatus = await Geolocation.checkPermissions()
      this.permissionsNative.location$.next(permissionStatus.location)
      this.permissionsNative.coarseLocation$.next(permissionStatus.coarseLocation)
    } catch (error: any) {
      console.debug(error)
      Toast.show({
        text: error.toString(),
        duration: 'long',
        position: 'bottom',
      })
    }
  }

  private async requestPermissionNativeGeolocation() {
    try {
      let permissions: GeolocationPluginPermissions = { permissions: ['location'] }
      let permissionStatus = await Geolocation.requestPermissions(permissions)
      console.debug('Geolocation.requestPermissions(): ', permissionStatus)
    } catch (error: any) {
      console.debug(error)
      Toast.show({
        text: 'requestPermissionNativeGeolocation(): ' + error.toString(),
        duration: 'long',
        position: 'bottom',
      })
    }
  }

}
