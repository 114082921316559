import { Injectable } from '@angular/core'
import { Platform } from '@ionic/angular'
import { getPlatforms, isPlatform } from '@ionic/angular'

@Injectable({
  providedIn: 'root',
})
export class PlatformService {
  /* This service should apply general platform handling */

  public ready: boolean = false
  public platforms!: string[]
  public platformType!: string
  public isPortrait!: boolean
  public widthScreen!: number
  public heightScreen!: number
  public widthWindow!: number
  public heightWindow!: number

  constructor(public platform: Platform) { }

  /* Lifecycle */
  async onInit() {
    console.info('PlatformService.onInit()..')

    // wait for platform to be ready
    await this.platform.ready()
    this.ready = true

    // get platforms
    this.platforms = this.platform.platforms()
    // console.debug(this.platforms.includes('mobileweb'))
    // && this.root.trunk.platform.platforms.has('mobileweb')

    // dimensions
    this.isPortrait = this.platform.isPortrait()
    this.widthScreen = this.platform.width()
    this.heightScreen = this.platform.height()
    this.widthWindow = document.documentElement.clientWidth
    this.heightWindow = document.documentElement.clientHeight
    // this.widthWindow = window.visualViewport.width;
    // this.heightWindow = window.visualViewport.height;

    // set type of platform
    await this.setPlatformType()

    // set doc dimensions
    await this.setDocHeightCssVariable()
    await this.setDocWidthCssVariable()

  }

  // Init() is called from app.module.ts and will block the app until finished
  Init() {
    return new Promise<void>(async (resolve, reject) => {
      console.info('PlatformService.init()..')
      await this.onInit()

      console.log('PlatformService: Ready')
      resolve()
    })
  }

  /* Service Functions */
  private async setPlatformType() {
    if (!isPlatform('capacitor')) {
      this.platformType = 'pwa'
    }
    if (isPlatform('capacitor')) {
      this.platformType = 'native'
    }
    // console.debug(this.platformType)
  }

  private async setDocHeightCssVariable() {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`)
  }

  private async setDocWidthCssVariable() {
    document.documentElement.style.setProperty('--vw', `${window.innerWidth / 100}px`)
  }
}
