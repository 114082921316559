import { Component } from '@angular/core'
import { Browser } from '@capacitor/browser'
import { RootService } from 'src/app/services/root.service'

@Component({
  selector: 'page-home',
  templateUrl: 'home.page.html',
  styleUrls: ['./home.page.scss'],
})
export class HomePage {
  constructor(public root: RootService) {}

  /* Lifecycle */
  async ngOnInit() {
    console.info('ngOnInit: home')
    console.debug(this.root.router.url)
    console.debug(this.root.trunk.cache)
    if (
      !this.root.trunk.trash.userHasLoggedIn &&
      !this.root.trunk.trash.userHasLoggedOut &&
      this.root.trunk.cache.checkUserHasToken()
    ) {
      this.root.trunk.trash.userHasLoggedIn = true
      console.info('User token: ' + this.root.trunk.cache.personals.token)
      await this.root.trunk.processLogin()
    } else {
      return
    }
  }

  async ionViewWillEnter() {
    console.info('ionViewWillEnter: home')
  }

  async ionViewDidEnter() {
    console.info('ionViewDidEnter: home')
    this.root.trunk.reportContext('page', this.root.router.url)
  }

  async ionViewWillLeave() {
    console.info('ionViewWillLeave: home')
  }

  async ionViewDidLeave() {
    console.info('ionViewDidLeave: home')
  }

  async ngOnDestroy() {
    console.info('ngOnDestroy: home')
  }

  /* Handles */
  public async handleToLoginPage() {
    await this.root.trunk.reportLog('handleToLoginPage()')
    await this.root.trunk.nav.navLogin()
  }

  public async handleToRegistration() {
    await this.root.trunk.reportLog('handleToRegistration()')
    try {
      // open link
      await Browser.open({ url: this.root.trunk.api.besUrl + 'account/signup/' })
    } catch (error) {
      await this.root.trunk.reportException(error)
    }
  }
}
