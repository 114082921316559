import { Injectable, NgZone } from '@angular/core'
import { Geolocation, Position } from '@capacitor/geolocation'
import { Toast } from '@capacitor/toast'
import { BehaviorSubject } from 'rxjs'
import { ItemLocation } from '../interfaces/data'

@Injectable({
  providedIn: 'root',
})
export class GeolocationService {
  /* This service should handle the user's position */
  watchIds: string[] = []
  geolocationOptions = {
    enableHighAccuracy: true,
    timeout: 10000,
  }

  // position stuff
  public defaultPosition: Position = {
    coords: {
      latitude: 52.0034366,
      longitude: 4.3628315,
    },
  } as Position
  public position$: BehaviorSubject<Position> = new BehaviorSubject(this.defaultPosition)
  public atDefaultPosition: boolean = true
  public pannedToPositionOneTime: boolean = false

  constructor(public ngZone: NgZone) {}

  /* Lifecycle */
  private async onInit() {
    console.info('GeolocationService.onInit()..')
    console.debug(this.position$.value)
  }

  // Init() is called from app.module.ts and will block the app until finished
  Init() {
    return new Promise<void>(async (resolve, reject) => {
      console.info('GeolocationService.init()..')
      await this.onInit()

      console.info('GeolocationService: Ready')
      resolve()
    })
  }

  /* GPS METHODS */
  public async getPosition() {
    try {
      let position: Position = await Geolocation.getCurrentPosition(this.geolocationOptions)
      return position
    } catch (error) {
      console.debug(error)
      Toast.show({
        text: 'Can not get location. Please make sure device location is enabled..',
        duration: 'long',
        position: 'bottom',
      })
      return null
    }
  }

  public async watchPosition() {
    try {
      this.watchIds.push(
        await Geolocation.watchPosition({}, (position, error) => {
          if (error) {
            console.debug(error)
            return
          }
          if (position !== null) {
            this.position$.next(position)
          }
        })
      )
    } catch (error) {
      console.log('watchPosition(): ', error)
    }
  }

  public async checkAtDefaultPosition() {
    if (this.atDefaultPosition) {
      if (this.position$.value !== this.defaultPosition) {
        this.atDefaultPosition = false
      }
    }
  }

  public async clearWatch() {
    // pop all watch IDs
    let watchIds = this.watchIds
    this.watchIds = []

    // and clear all watch instances
    for (let watchID of watchIds) {
      // console.debug('Clearing position watch: ' + watchID)
      if (watchID != null) {
        await Geolocation.clearWatch({ id: watchID })
      }
    }
  }

  /* Distance */
  public async checkUserAtLocation(position: Position, location: ItemLocation) {
    let userAtLocation: boolean = false
    let distanceToLocation = this.getDistanceFromLatLon(
      location.lat,
      location.lon,
      position.coords.latitude,
      position.coords.longitude
    )
    console.debug(`distanceToLocation: ${distanceToLocation}m`)
    if (distanceToLocation < 100) {
      userAtLocation = true
    }
    return userAtLocation
  }

  private getDistanceFromLatLon(lat1: number, lon1: number, lat2: number, lon2: number) {
    var R = 6371 // Radius of the earth in km
    var dLat = this.deg2rad(lat2 - lat1) // deg2rad below
    var dLon = this.deg2rad(lon2 - lon1)
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(lat1)) *
        Math.cos(this.deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2)
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    var d = R * c * 1000 // Distance in m
    return Math.round(d)
  }

  private deg2rad(deg: number) {
    return deg * (Math.PI / 180)
  }
}
