import { Injectable } from '@angular/core'
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http'
import { CookieService } from 'ngx-cookie-service'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import {
  ResponseAuthenticate,
  ResponseGeneric,
  ResponseItemLocation,
  ResponseItemMeasurement,
  ResponseItemProject,
  ResponseListClients,
  ResponseListLocationsMap,
  ResponseListProjects,
  ResponseListRegions,
  ResponseRegister,
} from '../interfaces/api'
import { ConnectionStatus, Network } from '@capacitor/network'
import { Toast } from '@capacitor/toast'
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  public networkStatus!: ConnectionStatus
  private cancelRequests$: Subject<any> = new Subject<any>()
  public domain: string = environment.domain
  public besUrl: string
  public mediaUrl: string
  public apiUrl: string
  public appUrl: string

  constructor(private httpClient: HttpClient, private cookieService: CookieService) {
    // URLs
    this.besUrl = this.domain + 'gg/'
    this.mediaUrl = this.domain + 'media/'
    this.apiUrl = this.besUrl + 'api/'
    this.appUrl = this.apiUrl + 'app/'
  }

  /* Lifecycle */
  private async onInit() {
    console.info('ApiService.onInit()..')

    // network status
    await this.getNetworkStatusOnInit()
    this.onChangeNetworkStatus()
  }

  // Init() is called from app.module.ts and will block the app until finished
  Init() {
    return new Promise<void>(async (resolve, reject) => {
      console.info('ApiService.init()..')
      await this.onInit()
      console.info('ApiService: Ready')
      resolve()
    })
  }

  /* Register */
  public postRegister(username: string, email: string, password1: string, password2: string) {
    // url
    let url = this.apiUrl + 'rest/registration/'

    // body
    let body = {
      username: username,
      email: email,
      password1: password1,
      password2: password2,
    }
    console.debug('Body postRegister:')
    console.debug(body)

    // headers
    let headers = new HttpHeaders()

    // return request
    return this.httpClient
      .post<ResponseRegister>(url, body, { headers: headers })
      .pipe(takeUntil(this.cancelRequests$))
  }

  /* Authenticate */
  public postAuthenticate(username: string, password: string) {
    // url
    let url = this.apiUrl + 'rest/login/'

    // body
    let body = {
      username: username,
      password: password,
    }

    // headers
    let headers = new HttpHeaders()
    headers = this.setHeaderCSRF(headers)

    // return request
    return this.httpClient
      .post<ResponseAuthenticate>(url, body, { headers: headers })
      .pipe(takeUntil(this.cancelRequests$))
  }

  /* Client */
  public getClients(token: string) {
    // url
    let url = this.appUrl + 'client/?format=json'

    // headers
    let headers = new HttpHeaders()
    headers = headers.append('Authorization', 'Token ' + token)

    // return request
    return this.httpClient
      .get<ResponseListClients>(url, { headers: headers })
      .pipe(takeUntil(this.cancelRequests$))
  }

  /* Project */
  public getProjects(token: string) {
    // url
    let url = this.appUrl + 'project/?format=json'

    // headers
    let headers = new HttpHeaders()
    headers = headers.append('Authorization', 'Token ' + token)

    // return request
    return this.httpClient
      .get<ResponseListProjects>(url, { headers: headers })
      .pipe(takeUntil(this.cancelRequests$))
  }

  public patchProject(token: string, projectID: number, body: FormData) {
    // url
    let url = this.appUrl + 'project/' + projectID + '/?format=json'

    // body
    console.debug('Body patchProject:')
    console.debug(body)

    // headers
    let headers = new HttpHeaders()
    headers = headers.append('Authorization', 'Token ' + token)

    // return request
    return this.httpClient
      .patch<ResponseItemProject>(url, body, { headers: headers })
      .pipe(takeUntil(this.cancelRequests$))
  }

  /* Location */
  public postLocation(token: string, body: FormData) {
    // url
    let url = this.appUrl + 'location/'

    // headers
    let headers = new HttpHeaders()
    headers = headers.append('Authorization', 'Token ' + token)

    // return request
    return this.httpClient
      .post<ResponseItemLocation>(url, body, { headers: headers })
      .pipe(takeUntil(this.cancelRequests$))
  }

  public getLocationsMap(token: string, filter: string) {
    // url
    let url = this.appUrl + 'location/?page=map&format=json'

    // filters
    url = url + filter
    console.debug(url)

    // headers
    let headers = new HttpHeaders()
    headers = headers.append('Authorization', 'Token ' + token)

    // return request
    return this.httpClient
      .get<ResponseListLocationsMap>(url, { headers: headers })
      .pipe(takeUntil(this.cancelRequests$))
  }

  public getLocation(token: string, locationID: number) {
    // url
    let url = this.appUrl + 'location/' + locationID + '/?format=json'

    // headers
    let headers = new HttpHeaders()
    headers = headers.append('Authorization', 'Token ' + token)

    // return request
    return this.httpClient
      .get<ResponseItemLocation>(url, { headers: headers })
      .pipe(takeUntil(this.cancelRequests$))
  }

  public deleteLocation(token: string, locationID: number) {
    // url
    let url = this.appUrl + 'location/' + locationID + '/?format=json'

    // headers
    let headers = new HttpHeaders()
    headers = headers.append('Authorization', 'Token ' + token)

    // return request
    return this.httpClient
      .delete<ResponseItemLocation>(url, { headers: headers })
      .pipe(takeUntil(this.cancelRequests$))
  }

  public patchLocation(token: string, locationID: number, body: FormData) {
    // url
    let url = this.appUrl + 'location/' + locationID + '/?format=json'

    // body
    console.debug('Body patchLocation:')
    console.debug(body)

    // headers
    let headers = new HttpHeaders()
    headers = headers.append('Authorization', 'Token ' + token)
    //headers = headers.append('Content-Type', 'application/x-www-form-urlencoded:');

    // return request
    return this.httpClient
      .patch<ResponseItemLocation>(url, body, { headers: headers })
      .pipe(takeUntil(this.cancelRequests$))
  }

  /* Measurement */
  public postMeasurement(token: string, body: FormData) {
    // url
    let url = this.appUrl + 'measurement/'

    // body
    console.debug('Body postMeasurement:')
    console.debug(body)

    // headers
    let headers = new HttpHeaders()
    headers = headers.append('Authorization', 'Token ' + token)

    // return request
    return this.httpClient
      .post<ResponseItemMeasurement>(url, body, { headers: headers })
      .pipe(takeUntil(this.cancelRequests$))
  }

  public getMeasurement(token: string, measurementID: number) {
    // url
    let url = this.appUrl + 'measurement/' + measurementID + '/?format=json'

    // headers
    let headers = new HttpHeaders()
    headers = headers.append('Authorization', 'Token ' + token)

    // return request
    return this.httpClient
      .get<ResponseItemMeasurement>(url, { headers: headers })
      .pipe(takeUntil(this.cancelRequests$))
  }

  public deleteMeasurement(token: string, measurementID: number) {
    // url
    let url = this.appUrl + 'measurement/' + measurementID + '/?format=json'

    // headers
    let headers = new HttpHeaders()
    headers = headers.append('Authorization', 'Token ' + token)

    // return request
    return this.httpClient
      .delete<ResponseItemMeasurement>(url, { headers: headers })
      .pipe(takeUntil(this.cancelRequests$))
  }

  public patchMeasurement(token: string, measurementID: number, body: FormData) {
    // url
    let url = this.appUrl + 'measurement/' + measurementID + '/?format=json'

    // body
    console.debug('Body patchMeasurement:')
    console.debug(body)

    // headers
    let headers = new HttpHeaders()
    headers = headers.append('Authorization', 'Token ' + token)

    // return request
    return this.httpClient
      .patch<ResponseItemMeasurement>(url, body, { headers: headers })
      .pipe(takeUntil(this.cancelRequests$))
  }

  /* Region */
  public getRegions(token: string) {
    // url
    let url = this.appUrl + 'region/?format=json'

    // headers
    let headers = new HttpHeaders()
    headers = headers.append('Authorization', 'Token ' + token)

    // return request
    return this.httpClient
      .get<ResponseListRegions>(url, { headers: headers })
      .pipe(takeUntil(this.cancelRequests$))
  }

  /* Helpers */
  public async showHttpResponse(response: ResponseGeneric) {
    // this still needs some typescripting
    // init
    let responseMsg: string = 'No detailed HTTP response message..'

    // handle
    if (!response) {
      responseMsg = 'No response from server'
    } else if (response.message) {
      responseMsg = response.message
      if (responseMsg == 'Verification e-mail sent.') {
        responseMsg = responseMsg + ' Please open your email and follow the instructions.'
      }
      // report response only when detail sent with response
      Toast.show({
        text: responseMsg,
        duration: 'long',
        position: 'bottom',
      })
    }

    // report
    console.debug(response)
    console.debug(responseMsg)
  }

  public async showHttpError(httpErrorResponse: HttpErrorResponse) {
    console.debug('showHttpError()')
    // network status
    if (!this.networkStatus.connected) {
      this.showNetworkStatus()
      return
    }

    // init
    console.debug(httpErrorResponse)
    let error = httpErrorResponse.error
    console.debug(error)
    let errorStatusCode: number = 999
    let errorMsg: string = 'Missing HTTP error message..'
    let errorList: string[] = []
    let errorReturn: string = 'No error report'

    // handle - much conditional logic if-else - revise?
    if (!httpErrorResponse) {
      errorMsg = 'No error response from server'
    } else {
      // status code
      if (httpErrorResponse.status) {
        errorStatusCode = httpErrorResponse.status
      }

      // error
      if (httpErrorResponse.statusText == 'Unknown Error') {
        // directly display unknown error
        errorMsg = 'Sorry, server is not responding..'
      } else if (error) {
        console.debug('error')

        for (const key in error) {
          // add error messages to list
          // console.debug(`${key}: ${error[key]}`);
          let entryFlat = error[key].join(' - ')
          // console.debug(entryFlat)
          errorList.push(entryFlat)
        }

        // convert error list to message
        // errorMsg = errorList[0]; // either just the first error message
        console.debug(errorList)
        errorMsg = errorList.join(' - ') // or the whole list
      } else {
        // message is in error.statusText
        errorMsg = httpErrorResponse.statusText
      }
    }

    // display error message with code
    errorReturn = errorStatusCode + ': ' + errorMsg
    console.error(errorReturn)
    Toast.show({
      text: errorMsg,
      duration: 'long',
      position: 'bottom',
    })
  }

  private async getNetworkStatusOnInit() {
    // assign
    this.networkStatus = await Network.getStatus()

    // message
    this.showNetworkStatus()
  }

  private async onChangeNetworkStatus() {
    Network.addListener('networkStatusChange', (status) => {
      // assign change
      this.networkStatus = status

      // message
      this.showNetworkStatus()
    })
  }

  private async showNetworkStatus() {
    // message
    let msg: string = ''
    if (this.networkStatus.connected) {
      msg = 'Network status: connected on ' + this.networkStatus.connectionType.toString()
    } else {
      msg = 'Network status: no connection'
    }

    // display message
    console.debug(msg)
    // Toast.show({
    //   text: msg,
    //   duration: 'long',
    //   position: 'bottom',
    // })
  }

  private setHeaderCSRF(headers: HttpHeaders) {
    // CSRF
    if (this.cookieService.check('csrftoken')) {
      headers = headers.append('X-CSRFToken', this.cookieService.get('csrftoken'))
    } else {
      let msg = 'No CSRF cookie found..'
      console.debug(msg)
      // Toast.show({
      //   text: msg,
      //   duration: 'long',
      //   position: 'bottom',
      // })
    }
    return headers
  }
}
