import { Position } from '@capacitor/geolocation'
import { latLng } from 'leaflet'

export async function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export function roundTwoDec(value: number) {
  return Math.round(value * 100) / 100
}

export async function toPercentage(num: number) {
  return `${Math.round(num * 100)}%`
}

export function posToLatLng(position: Position) {
  return latLng(position.coords.latitude, position.coords.longitude)
}

export function getYearFromTimestamp(timestamp: string) {
  var d = new Date(timestamp)
  return d.getFullYear()
}

export function getTimestampWithoutYear(timestamp: string) {
  var d = new Date(timestamp)
  d.setFullYear(0)
  return d.toISOString()
}

export function getMonthFromTimestamp(timestamp: string) {
  var d = new Date(timestamp)
  let months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  // return d.getFullYear()
  return months[d.getMonth()]
}

export function arrayRange(start: number, stop: number, step: number, digits: number) {
  console.debug(start, stop, step, digits)
  return Array.from({ length: (stop - start) / step + 1 }, (value, index) => {
    return Math.round((start + index * step) * 10 ** digits) / 10 ** digits
  })
}
